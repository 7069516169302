import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { StoreContext } from 'pages/_app';
import { toJS } from 'mobx';
import DatePicker from 'components/DatePicker';
import TimePicker from 'components/TimePicker';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'next/router';
import moment from 'moment-timezone';
import QueryString from 'qs';
import GoogleAutoComplete from 'components/GoogleAutoComplete';
import AddressPicker from 'components/AddressPicker';

const Error = () => (
  <span className="text-attention text-xs mt-1 animate-fade-in-down">
    This field needs to be filled
  </span>
);

function MobileDateTimeModal({ getAvailableTimes, getAvailableDates }) {
  const [internalAddress, setAddress] = useState(null);
  const [addressError, setAddressError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);
  const restaurantStore = toJS(store?.restaurantStore);
  const {
    address,
    completeAddress,
    selectedDate,
    selectedTime,
    availableDates,
    mobileDateTimeModal,
    userLogin,
    isAdmin,
  } = userStore;
  const { activeRestaurant } = restaurantStore;

  const router = useRouter();

  let date = selectedDate;
  let time = selectedTime;

  useEffect(() => {
    if (date && dateError) setDateError(false);

    if (time && timeError) setTimeError(false);
  }, [date, time]);

  const closeModal = () => {
    store?.userStore?.setMobileDateTimeModal(false);
  };

  useEffect(() => {
    if (mobileDateTimeModal) {
      setAddress({ address, completeAddress });
    }
  }, [mobileDateTimeModal]);

  const apply = async () => {
    if (!internalAddress?.address) {
      setAddressError(true);
    } else if (!date) {
      setDateError(true);
    } else if (!time) {
      setTimeError(true);
    } else {
      store?.userStore?.setAddress(internalAddress?.address, internalAddress?.completeAddress);

      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            date: moment(date).format('YYYY-MM-DD'),
            time,
            address: internalAddress?.address,
          },
        },
        undefined,
        {
          shallow: true,
        }
      );

      closeModal();
    }
  };

  const goBack = () => {
    let backQuery = QueryString.parse(router?.query);
    delete backQuery['id'];
    delete backQuery['name'];
    delete backQuery['utm_source'];

    router.replace({
      pathname: '/',
      query: {
        ...backQuery,
        date: moment(date).format('YYYY-MM-DD'),
        time,
      },
    });
  };

  const isValidInfo = () => {
    return date && time;
  };

  const handleOnChange = e => {
    if (addressError) setAddressError(false);
    setAddress({ address: e?.target?.value });
  };

  return (
    <Transition appear show={mobileDateTimeModal} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => () => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full bg-white md:bg-transparent md:items-center md:justify-center md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full h-full sm:h-auto md:w-6/12 max-w-[667px] transform overflow-visible sm:rounded-2xl md:rounded-2xl bg-white py-10 px-8 pt-14 text-left align-middle md:shadow-xl transition-all">
                <div className="flex flex-col mb-3 items-end">
                  <button type="button" onClick={closeModal} className="btn-style">
                    <FontAwesomeIcon
                      style={{ width: 16, height: 26 }}
                      icon={faXmark}
                      size="2x"
                      color="#BBC0C5"
                    />
                  </button>
                </div>

                <div>
                  <div className="flex flex-col mt-5">
                    <div className="flex w-full md:mr-2 h-12">
                      {userLogin && isAdmin ? (
                        <AddressPicker backgroundColor={false} setAddress={setAddress} />
                      ) : (
                        <GoogleAutoComplete
                          className={classNames(
                            'input-light-1 outline-none focus:outline-none address-inputs font-inter-medium my-0 rounded-lg text-md text-primary-black w-full bg-background pl-4',
                            {
                              'border-attention border': addressError,
                            }
                          )}
                          onChange={handleOnChange}
                          onBlur={e => {
                            const queryDate = router?.query?.date;

                            store.userStore.getAvailableDates({
                              address: e?.target?.value,
                              month: queryDate
                                ? moment(queryDate).month() + 1
                                : moment().month() + 1,
                              year: queryDate ? moment(queryDate).year() : moment().year(),
                              current_date_time: moment().format('YYYY-MM-DD HH:mm'),
                            });
                          }}
                          value={internalAddress?.address}
                          onPlaceSelected={place => {
                            const _address = place?.address_components?.reduce(
                              (seed, { long_name, short_name, types }) => {
                                types.forEach(t => {
                                  if (t == 'administrative_area_level_1') {
                                    seed[t] = short_name; // Set short_name for province
                                  } else {
                                    seed[t] = long_name; // Set long_name for other types
                                  }
                                });
                                return seed;
                              },
                              {}
                            );

                            store.userStore?.getAndSetTimezone(place?.formatted_address);

                            setAddress({
                              address: place?.formatted_address,
                              completeAddress: {
                                suite: _address?.subpremise,
                                street_number: _address?.street_number,
                                street_name: _address?.route,
                                city: _address?.locality,
                                province: _address?.administrative_area_level_1,
                                postal_code: _address?.postal_code,
                              },
                            });
                          }}
                        />
                      )}
                    </div>

                    {addressError && <Error />}
                  </div>

                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="flex flex-col w-full my-4">
                      <DatePicker
                        fromModal
                        isCart={false}
                        internalAddress={internalAddress?.address}
                        selectedDate={date}
                        activeRestaurant={activeRestaurant}
                        getAvailableTimes={getAvailableTimes}
                        getAvailableDates={getAvailableDates}
                        availableDates={availableDates}
                        minDate={new Date()}
                        format={'MMMM d, yyyy'}
                        className="flex h-12 bg-background rounded-lg pr-2 items-center"
                      />

                      {dateError && <Error />}
                    </div>

                    <div className="flex flex-col w-full">
                      <TimePicker
                        fromModal
                        labelName="mobile-time-picker"
                        isCart={false}
                        selectedDate={date}
                        selectedTime={time}
                        className="flex h-12 bg-background justify-between rounded-lg text-sm border-0 text-primary-black"
                      />

                      {timeError && <Error />}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>

        <Transition.Child
          as={'div'}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="md:hidden">
            <div className="fixed bg-white w-full py-5 bottom-0 z-60 justify-center items-center mt-16 border-t-2 border-gray-200">
              <div className="flex px-5 justify-evenly">
                <button
                  className={classNames('btn-purple w-full sm:w-5/12 py-3 px-6 ml-3', {
                    'text-secondary-gray hover:text-secondary-gray': !isValidInfo(),
                  })}
                  // disabled={!isValidInfo()}
                  onClick={apply}>
                  Apply
                </button>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}

export default observer(MobileDateTimeModal);
